import {verticalListAll} from '~/globals/forms.js'

export const makeId = (length) => {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

const date = new Date()

const getEmailOptions = (emails, subject, formFields, key) => {
  let email = {}
  const logoUrl = window.location.origin + '/logo/pininfarina-logo.png'
  const year = date.getFullYear()

  let body_html = `<!doctype html>
  <html lang="en">
    <head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
      <title>Simple Transactional Email</title>
      <style media="all" type="text/css">
        /* -------------------------------------
          GLOBAL RESETS
        ------------------------------------- */
        body {
          font-family: Helvetica, sans-serif;
          -webkit-font-smoothing: antialiased;
          font-size: 16px;
          line-height: 1.3;
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%;
        }
        
        table {
          border-collapse: separate;
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
          width: 100%;
        }
        
        table td {
          font-family: Helvetica, sans-serif;
          font-size: 16px;
          vertical-align: top;
          margin-bottom: 10px;
        }
        /* 
        -------------------------------------
            BODY & CONTAINER
        ------------------------------------- */
        body {
          background-color: #f4f5f6;
          margin: 0;
          padding: 0;
        }
        
        .body {
          background-color: #f4f5f6;
          width: 100%;
        }
        
        .container {
          margin: 0 auto !important;
          max-width: 600px;
          padding: 0;
          padding-top: 24px;
          padding-bottom: 20px;
          width: 600px;
        }
        
        .content {
          box-sizing: border-box;
          display: block;
          margin: 0 auto;
          max-width: 600px;
          padding: 0;
        }

        /* -------------------------------------
            HEADER, FOOTER, MAIN
        ------------------------------------- */
        .header {
          clear: both;
          padding-bottom: 24px;
          text-align: center;
          width: 100%;
        }

        .header .logo{
          max-height: 30px;
          width: auto;
        }

        .main {
          background: #ffffff;
          border: 1px solid #eaebed;
          border-radius: 16px;
          width: 100%;
        }
        
        .wrapper {
          box-sizing: border-box;
          padding: 24px;
        }
        
        .footer {
          clear: both;
          padding-top: 24px;
          text-align: center;
          width: 100%;
        }
        
        .footer td,
        .footer p,
        .footer span,
        .footer a {
          color: #9a9ea6;
          font-size: 16px;
          text-align: center;
        }
        /* -------------------------------------
            TYPOGRAPHY
        ------------------------------------- */
        p {
          font-family: Helvetica, sans-serif;
          font-size: 16px;
          font-weight: normal;
          margin: 0;
          margin-bottom: 16px;
        }
        
        a {
          color: #081427;
          text-decoration: underline;
        }
        
        /* -------------------------------------
            OTHER STYLES THAT MIGHT BE USEFUL
        ------------------------------------- */  
        .align-center {
          text-align: center;
        }

        .clear {
          clear: both;
        }
        
        .mt0 {
          margin-top: 0;
        }
        
        .mb0 {
          margin-bottom: 0;
        }
        
        .preheader {
          color: transparent;
          display: none;
          height: 0;
          max-height: 0;
          max-width: 0;
          opacity: 0;
          overflow: hidden;
          mso-hide: all;
          visibility: hidden;
          width: 0;
        }
          
        /* -------------------------------------
            RESPONSIVE AND MOBILE FRIENDLY STYLES
        ------------------------------------- */
        @media only screen and (max-width: 640px) {
          .main p,
          .main td,
          .main span {
            font-size: 16px !important;
          }
          .wrapper {
            padding: 8px !important;
          }
          .content {
            padding: 0 !important;
          }
          .container {
            padding: 0 !important;
            padding-top: 8px !important;
            padding-bottom: 8px !important;
            width: 100% !important;
          }
          .main {
            border-left-width: 0 !important;
            border-radius: 0 !important;
            border-right-width: 0 !important;
          }
          .btn table {
            max-width: 100% !important;
            width: 100% !important;
          }
          .btn a {
            font-size: 16px !important;
            max-width: 100% !important;
            width: 100% !important;
          }
        }
      </style>
    </head>
    <body>
      <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body">
        <tr>
          <td>&nbsp;</td>
          <td class="container">
            <!-- START CENTERED WHITE CONTAINER -->
            <div class="content">
              <span class="preheader">This email was sent from a contact form on Pininfarina website</span>
              <div class="header align-center">
                <img class="logo" src="${logoUrl}" alt="">
              </div>
              <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="main">
                <!-- START MAIN CONTENT AREA -->
                <tr>
                  <td class="wrapper">
                      <p>
                          Hello,<br>
                          you've received a new email.
                      </p>
                      <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="btn btn-primary">
                          <tbody>
                              <tr>
                                  <td align="left">
                                      <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                          <tbody>
                                              <tr>
                                                  <td>Name: <b>${
                                                    formFields.name
                                                  } ${
    formFields.surname
  }</b></td>
                                              </tr>
                                              <tr>
                                                  <td>Email: <b>${
                                                    formFields.email
                                                  }</b></td>
                                              </tr>
                                              ${
                                                formFields.phone
                                                  ? '<tr><td>Phone number: <b>' +
                                                    formFields.phone +
                                                    '</b></td></tr>'
                                                  : ''
                                              }
                                              ${
                                                formFields.company
                                                  ? '<tr><td>Company: <b>' +
                                                    formFields.company +
                                                    '</b></td></tr>'
                                                  : ''
                                              }
                                              <tr>
                                                <td>Interested in: <b>${
                                                  formFields.vertical
                                                }</b></td>
                                              </tr>
                                              ${
                                                formFields.legal
                                                  ? '<tr><td>Legal: <b>' +
                                                    formFields.legal +
                                                    '</b></td></tr>'
                                                  : ''
                                              }
                                              <tr>
                                                  <td>
                                                    ${
                                                      formFields.message
                                                        ? 'Message:<br>' +
                                                          formFields.message
                                                        : ''
                                                    }
                                                  </td>
                                              </tr>
                                          </tbody>
                                      </table>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                  </td>
                </tr>
              <!-- END MAIN CONTENT AREA -->
              </table>

              <!-- START FOOTER -->
              <div class="footer">
              <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                <tr>
                  <td class="content-block">
                      © PININFARINA ${year}
                  </td>
                </tr>
              </table>
              </div>
              <!-- END FOOTER -->
            </div>
            <!-- END CENTERED WHITE CONTAINER -->
          </td>
          <td>&nbsp;</td>
        </tr>
      </table>
    </body>
  </html>`
  let body_text = ``

  email = {
    to: emails,
    subject: subject + ' - ' + formFields.vertical,
    body: {
      html: body_html,
      text: body_text,
    },
    key: key,
  }

  return email
}

export const getJobApplyEmailOptions = (
  jobPosition,
  emails,
  subject,
  formFields,
  key
) => {
  let email = {}
  const logoUrl = window.location.origin + '/logo/pininfarina-logo.png'
  const year = date.getFullYear()

  let body_html = `<!doctype html>
  <html lang="en">
    <head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
      <title>Simple Transactional Email</title>
      <style media="all" type="text/css">
        /* -------------------------------------
          GLOBAL RESETS
        ------------------------------------- */
        body {
          font-family: Helvetica, sans-serif;
          -webkit-font-smoothing: antialiased;
          font-size: 16px;
          line-height: 1.3;
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%;
        }
        
        table {
          border-collapse: separate;
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
          width: 100%;
        }
        
        table td {
          font-family: Helvetica, sans-serif;
          font-size: 16px;
          vertical-align: top;
          margin-bottom: 10px;
        }
        /* 
        -------------------------------------
            BODY & CONTAINER
        ------------------------------------- */
        body {
          background-color: #f4f5f6;
          margin: 0;
          padding: 0;
        }
        
        .body {
          background-color: #f4f5f6;
          width: 100%;
        }
        
        .container {
          margin: 0 auto !important;
          max-width: 600px;
          padding: 0;
          padding-top: 24px;
          padding-bottom: 20px;
          width: 600px;
        }
        
        .content {
          box-sizing: border-box;
          display: block;
          margin: 0 auto;
          max-width: 600px;
          padding: 0;
        }

        /* -------------------------------------
            HEADER, FOOTER, MAIN
        ------------------------------------- */
        .header {
          clear: both;
          padding-bottom: 24px;
          text-align: center;
          width: 100%;
        }

        .header .logo{
          max-height: 30px;
          width: auto;
        }

        .main {
          background: #ffffff;
          border: 1px solid #eaebed;
          border-radius: 16px;
          width: 100%;
        }
        
        .wrapper {
          box-sizing: border-box;
          padding: 24px;
        }
        
        .footer {
          clear: both;
          padding-top: 24px;
          text-align: center;
          width: 100%;
        }
        
        .footer td,
        .footer p,
        .footer span,
        .footer a {
          color: #9a9ea6;
          font-size: 16px;
          text-align: center;
        }
        /* -------------------------------------
            TYPOGRAPHY
        ------------------------------------- */
        p {
          font-family: Helvetica, sans-serif;
          font-size: 16px;
          font-weight: normal;
          margin: 0;
          margin-bottom: 16px;
        }
        
        a {
          color: #081427;
          text-decoration: underline;
        }
        
        /* -------------------------------------
            OTHER STYLES THAT MIGHT BE USEFUL
        ------------------------------------- */  
        .align-center {
          text-align: center;
        }

        .clear {
          clear: both;
        }
        
        .mt0 {
          margin-top: 0;
        }
        
        .mb0 {
          margin-bottom: 0;
        }
        
        .preheader {
          color: transparent;
          display: none;
          height: 0;
          max-height: 0;
          max-width: 0;
          opacity: 0;
          overflow: hidden;
          mso-hide: all;
          visibility: hidden;
          width: 0;
        }
          
        /* -------------------------------------
            RESPONSIVE AND MOBILE FRIENDLY STYLES
        ------------------------------------- */
        @media only screen and (max-width: 640px) {
          .main p,
          .main td,
          .main span {
            font-size: 16px !important;
          }
          .wrapper {
            padding: 8px !important;
          }
          .content {
            padding: 0 !important;
          }
          .container {
            padding: 0 !important;
            padding-top: 8px !important;
            padding-bottom: 8px !important;
            width: 100% !important;
          }
          .main {
            border-left-width: 0 !important;
            border-radius: 0 !important;
            border-right-width: 0 !important;
          }
          .btn table {
            max-width: 100% !important;
            width: 100% !important;
          }
          .btn a {
            font-size: 16px !important;
            max-width: 100% !important;
            width: 100% !important;
          }
        }
      </style>
    </head>
    <body>
      <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body">
        <tr>
          <td>&nbsp;</td>
          <td class="container">
            <!-- START CENTERED WHITE CONTAINER -->
            <div class="content">
              <span class="preheader">This email was sent from a contact form on Pininfarina website</span>
              <div class="header align-center">
                <img class="logo" src="${logoUrl}" alt="">
              </div>
              <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="main">
                <!-- START MAIN CONTENT AREA -->
                <tr>
                  <td class="wrapper">
                      <p>
                          Hello,<br>
                          you've a new job application.
                      </p>
                      <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="btn btn-primary">
                          <tbody>
                              <tr>
                                  <td align="left">
                                      <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                          <tbody>
                                            <tr>
                                                <td>Position: <b>${jobPosition}</b></td>
                                              </tr>
                                              <tr>
                                                <td>Name: <b>${
                                                  formFields.name
                                                } ${formFields.surname}</b></td>
                                              </tr>
                                              <tr>
                                                <td>Email: <b>${
                                                  formFields.email
                                                }</b></td>
                                              </tr>
                                              <tr>
                                                <td>Phone number: <b>${
                                                  formFields.phone
                                                }</b></td>
                                                </tr>
                                              <tr>
                                              <td>Birth date: <b>${
                                                formFields.birthdate
                                              }</b></td>
                                              </tr>
                                              <tr>
                                                <td>
                                                  Curriculum: <a href="https://pininfarina-attachments-prod.s3.eu-central-1.amazonaws.com/${
                                                    formFields.cv_attachment
                                                  }" target="_blank"><b>${
    formFields.cv_attachment
  }</b></a>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>
                                                  ${
                                                    formFields.portfolio_attachment
                                                      ? 'Portfolio: <a href="https://pininfarina-attachments-prod.s3.eu-central-1.amazonaws.com/' +
                                                        formFields.portfolio_attachment +
                                                        '" target="_blank"><b>' +
                                                        formFields.portfolio_attachment +
                                                        '</b></a>'
                                                      : ''
                                                  }
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>
                                                  ${
                                                    formFields.photo_attachment
                                                      ? 'Photo: <a href="https://pininfarina-attachments-prod.s3.eu-central-1.amazonaws.com/' +
                                                        formFields.photo_attachment +
                                                        '" target="_blank"><b>' +
                                                        formFields.photo_attachment +
                                                        '</b></a>'
                                                      : ''
                                                  }
                                                </td>
                                              </tr>
                                              <tr>
                                                  <td>
                                                    ${
                                                      formFields.cover_letter
                                                        ? 'Presentation letter:<br><b>' +
                                                          formFields.cover_letter +
                                                          '</b>'
                                                        : ''
                                                    }
                                                  </td>
                                              </tr>
                                              <tr>
                                                <td>Public administration: <b>${
                                                  formFields.pa_check
                                                }</b></td>
                                              </tr>
                                              <tr>
                                                <td>Administrative or judicalmeasures: <b>${
                                                  formFields.ajm_check
                                                }</b></td>
                                              </tr>
                                              <tr>
                                                <td>Spouse or relatives involved: <b>${
                                                  formFields.rel_check
                                                }</b></td>
                                              </tr>
                                          </tbody>
                                      </table>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                  </td>
                </tr>
              <!-- END MAIN CONTENT AREA -->
              </table>

              <!-- START FOOTER -->
              <div class="footer">
              <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                <tr>
                  <td class="content-block">
                      © PININFARINA ${year}
                  </td>
                </tr>
              </table>
              </div>
              <!-- END FOOTER -->
            </div>
            <!-- END CENTERED WHITE CONTAINER -->
          </td>
          <td>&nbsp;</td>
        </tr>
      </table>
    </body>
  </html>`
  let body_text = ``

  email = {
    to: emails,
    subject: subject + ': ' + jobPosition,
    body: {
      html: body_html,
      text: body_text,
    },
    key: key,
  }

  return email
}

const getEmailAddresses = (vertical, legal, processedData) => {
  let email_string = ''
  let email_list = []

  if (vertical && processedData[vertical]) {
    // console.log('Email addresses: ', processedData[vertical][legal])
    email_string = processedData[vertical][legal]
    if (!email_string) email_string = processedData[vertical]['no_legal']
    email_list = email_string.split(', ')
  } else {
    console.error('Vertical not found or legal property not available')
    email_list = ['info@pininfarina.it']
  }

  return email_list
}

const {postData} = useApi()

const getLabelByValue = (value) => {
  const match = verticalListAll.find((item) => item.value === value)
  return match ? match.label : null
}

export const handlePostRequest = async (
  formFields,
  processedData,
  subject,
  key,
  bugsnag = null,
  runningEnv = 'local'
) => {
  let vertical = getLabelByValue(formFields.vertical)
  if (vertical == 'Mobility / Transportation')
    vertical = 'Mobility & Transportation'
  if (vertical == 'Collectors PF Classiche') vertical = 'PF Classiche'
  if (vertical == 'All sectors') vertical = 'All Sectors'

  let legal = formFields.legal ? formFields.legal : 'no_legal'
  let email_list = getEmailAddresses(vertical, legal, processedData)

  // console.log('Vertical received:', formFields.vertical, 'Vertical checked:',getLabelByValue(formFields.vertical), 'Legal:', legal)
  // console.log('Emails:', email_list)

  // Email for test
  // email_list = [
  //   'luca@quattrolinee.it',
  //   'matteo@quattrolinee.it',
  //   'f.moro@quattrolinee.it',
  // ]

  try {
    const firstEndpoint = 'https://d3k3focfeq9qbq.cloudfront.net/'
    const secondEndpoint = 'https://d3k3focfeq9qbq.cloudfront.net/'

    // const firstEndpoint =
    //   'https://jw7k7yu169.execute-api.eu-central-1.amazonaws.com/'
    // const secondEndpoint =
    //   'https://t63kyye8hi.execute-api.ap-east-1.amazonaws.com/'

    const response = await postData(
      firstEndpoint,
      secondEndpoint,
      getEmailOptions(email_list, subject, formFields, key),
      bugsnag,
      runningEnv
    )

    // Handle the response
    console.log('Response:', response)
    return response
  } catch (error) {
    console.error('Error:', error)
    return error
  }
}
