import { default as awards0fkSCneN8UMeta } from "/home/forge/pininfarina.it/pages/about/awards.vue?macro=true";
import { default as certificationsBcyPPtR7fbMeta } from "/home/forge/pininfarina.it/pages/about/certifications.vue?macro=true";
import { default as heritageP8sM0afWpcMeta } from "/home/forge/pininfarina.it/pages/about/heritage.vue?macro=true";
import { default as our_45groupMEp6Iht8sfMeta } from "/home/forge/pininfarina.it/pages/about/our-group.vue?macro=true";
import { default as partnersbJeTGd5ZpdMeta } from "/home/forge/pininfarina.it/pages/about/partners.vue?macro=true";
import { default as sustainabilityVt2EP7wLnlMeta } from "/home/forge/pininfarina.it/pages/about/sustainability.vue?macro=true";
import { default as teamSofY6eoDOOMeta } from "/home/forge/pininfarina.it/pages/about/team.vue?macro=true";
import { default as visit_45pininfarinaHNJB8Tv3dBMeta } from "/home/forge/pininfarina.it/pages/about/visit-pininfarina.vue?macro=true";
import { default as methodology8d4Psp2WqRMeta } from "/home/forge/pininfarina.it/pages/approach/methodology.vue?macro=true";
import { default as _91jobSlug_93ZQjYgoR1X3Meta } from "/home/forge/pininfarina.it/pages/careers/[jobSlug].vue?macro=true";
import { default as indexw5YMIyVtfpMeta } from "/home/forge/pininfarina.it/pages/careers/index.vue?macro=true";
import { default as indexbxGv1VpawRMeta } from "/home/forge/pininfarina.it/pages/classiche/index.vue?macro=true";
import { default as _91countrySlug_93IE8xQYVEX5Meta } from "/home/forge/pininfarina.it/pages/contacts/[countrySlug].vue?macro=true";
import { default as indexM0DR6nj1oZMeta } from "/home/forge/pininfarina.it/pages/contacts/index.vue?macro=true";
import { default as messageSentmbpSCELYufMeta } from "/home/forge/pininfarina.it/pages/contacts/messageSent.vue?macro=true";
import { default as cookie_45policyPF4ZbYdoxlMeta } from "/home/forge/pininfarina.it/pages/cookie-policy.vue?macro=true";
import { default as indexl4wm0xeLuKMeta } from "/home/forge/pininfarina.it/pages/index.vue?macro=true";
import { default as indexMuVk1mrFjSMeta } from "/home/forge/pininfarina.it/pages/investor-relations/[[investorSlug]]/index.vue?macro=true";
import { default as hq_45resourcesJq7nSpnNEoMeta } from "/home/forge/pininfarina.it/pages/media-hub/hq-resources.vue?macro=true";
import { default as indexoVMKatJeNhMeta } from "/home/forge/pininfarina.it/pages/media-hub/index.vue?macro=true";
import { default as loginGFlNoJq8Z6Meta } from "/home/forge/pininfarina.it/pages/media-hub/login.vue?macro=true";
import { default as _91newsSlug_93AmB5wLiYAyMeta } from "/home/forge/pininfarina.it/pages/news/[newsSlug].vue?macro=true";
import { default as indexgQMmgeNJkqMeta } from "/home/forge/pininfarina.it/pages/news/index.vue?macro=true";
import { default as successUIIH1nefHqMeta } from "/home/forge/pininfarina.it/pages/pardot/success.vue?macro=true";
import { default as privacy_45policycQgRovIg7LMeta } from "/home/forge/pininfarina.it/pages/privacy-policy.vue?macro=true";
import { default as _91projectSlug_93Nm2WcRZ6FAMeta } from "/home/forge/pininfarina.it/pages/projects/[projectSlug].vue?macro=true";
import { default as indexWiuHclYhjSMeta } from "/home/forge/pininfarina.it/pages/search/index.vue?macro=true";
import { default as indexZDfgCTkpQxMeta } from "/home/forge/pininfarina.it/pages/sectors/[sectorSlug]/index.vue?macro=true";
import { default as portfoliouMhfYOAQS3Meta } from "/home/forge/pininfarina.it/pages/sectors/[sectorSlug]/portfolio.vue?macro=true";
import { default as _91serviceSlug_930vahbAPz86Meta } from "/home/forge/pininfarina.it/pages/services/[serviceSlug].vue?macro=true";
import { default as index79ETnC9GNAMeta } from "/home/forge/pininfarina.it/pages/whistleblowing/index.vue?macro=true";
import { default as pininfarinaOzhUtZevD9Meta } from "/home/forge/pininfarina.it/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
export default [
  {
    name: awards0fkSCneN8UMeta?.name ?? "about-awards___de",
    path: awards0fkSCneN8UMeta?.path ?? "/de/um/auszeichnungen",
    meta: awards0fkSCneN8UMeta || {},
    alias: awards0fkSCneN8UMeta?.alias || [],
    redirect: awards0fkSCneN8UMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/awards.vue").then(m => m.default || m)
  },
  {
    name: awards0fkSCneN8UMeta?.name ?? "about-awards___en___default",
    path: awards0fkSCneN8UMeta?.path ?? "/about/awards",
    meta: awards0fkSCneN8UMeta || {},
    alias: awards0fkSCneN8UMeta?.alias || [],
    redirect: awards0fkSCneN8UMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/awards.vue").then(m => m.default || m)
  },
  {
    name: awards0fkSCneN8UMeta?.name ?? "about-awards___en",
    path: awards0fkSCneN8UMeta?.path ?? "/en/about/awards",
    meta: awards0fkSCneN8UMeta || {},
    alias: awards0fkSCneN8UMeta?.alias || [],
    redirect: awards0fkSCneN8UMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/awards.vue").then(m => m.default || m)
  },
  {
    name: awards0fkSCneN8UMeta?.name ?? "about-awards___it",
    path: awards0fkSCneN8UMeta?.path ?? "/it/about/premi",
    meta: awards0fkSCneN8UMeta || {},
    alias: awards0fkSCneN8UMeta?.alias || [],
    redirect: awards0fkSCneN8UMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/awards.vue").then(m => m.default || m)
  },
  {
    name: awards0fkSCneN8UMeta?.name ?? "about-awards___zh",
    path: awards0fkSCneN8UMeta?.path ?? "/zh/guanyu/jiangxiang",
    meta: awards0fkSCneN8UMeta || {},
    alias: awards0fkSCneN8UMeta?.alias || [],
    redirect: awards0fkSCneN8UMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/awards.vue").then(m => m.default || m)
  },
  {
    name: certificationsBcyPPtR7fbMeta?.name ?? "about-certifications___de",
    path: certificationsBcyPPtR7fbMeta?.path ?? "/de/about/certifications",
    meta: certificationsBcyPPtR7fbMeta || {},
    alias: certificationsBcyPPtR7fbMeta?.alias || [],
    redirect: certificationsBcyPPtR7fbMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/certifications.vue").then(m => m.default || m)
  },
  {
    name: certificationsBcyPPtR7fbMeta?.name ?? "about-certifications___en___default",
    path: certificationsBcyPPtR7fbMeta?.path ?? "/about/certifications",
    meta: certificationsBcyPPtR7fbMeta || {},
    alias: certificationsBcyPPtR7fbMeta?.alias || [],
    redirect: certificationsBcyPPtR7fbMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/certifications.vue").then(m => m.default || m)
  },
  {
    name: certificationsBcyPPtR7fbMeta?.name ?? "about-certifications___en",
    path: certificationsBcyPPtR7fbMeta?.path ?? "/en/about/certifications",
    meta: certificationsBcyPPtR7fbMeta || {},
    alias: certificationsBcyPPtR7fbMeta?.alias || [],
    redirect: certificationsBcyPPtR7fbMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/certifications.vue").then(m => m.default || m)
  },
  {
    name: certificationsBcyPPtR7fbMeta?.name ?? "about-certifications___it",
    path: certificationsBcyPPtR7fbMeta?.path ?? "/it/about/certifications",
    meta: certificationsBcyPPtR7fbMeta || {},
    alias: certificationsBcyPPtR7fbMeta?.alias || [],
    redirect: certificationsBcyPPtR7fbMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/certifications.vue").then(m => m.default || m)
  },
  {
    name: certificationsBcyPPtR7fbMeta?.name ?? "about-certifications___zh",
    path: certificationsBcyPPtR7fbMeta?.path ?? "/zh/about/certifications",
    meta: certificationsBcyPPtR7fbMeta || {},
    alias: certificationsBcyPPtR7fbMeta?.alias || [],
    redirect: certificationsBcyPPtR7fbMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/certifications.vue").then(m => m.default || m)
  },
  {
    name: heritageP8sM0afWpcMeta?.name ?? "about-heritage___de",
    path: heritageP8sM0afWpcMeta?.path ?? "/de/um/erbe",
    meta: heritageP8sM0afWpcMeta || {},
    alias: heritageP8sM0afWpcMeta?.alias || [],
    redirect: heritageP8sM0afWpcMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/heritage.vue").then(m => m.default || m)
  },
  {
    name: heritageP8sM0afWpcMeta?.name ?? "about-heritage___en___default",
    path: heritageP8sM0afWpcMeta?.path ?? "/about/heritage",
    meta: heritageP8sM0afWpcMeta || {},
    alias: heritageP8sM0afWpcMeta?.alias || [],
    redirect: heritageP8sM0afWpcMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/heritage.vue").then(m => m.default || m)
  },
  {
    name: heritageP8sM0afWpcMeta?.name ?? "about-heritage___en",
    path: heritageP8sM0afWpcMeta?.path ?? "/en/about/heritage",
    meta: heritageP8sM0afWpcMeta || {},
    alias: heritageP8sM0afWpcMeta?.alias || [],
    redirect: heritageP8sM0afWpcMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/heritage.vue").then(m => m.default || m)
  },
  {
    name: heritageP8sM0afWpcMeta?.name ?? "about-heritage___it",
    path: heritageP8sM0afWpcMeta?.path ?? "/it/about/heritage",
    meta: heritageP8sM0afWpcMeta || {},
    alias: heritageP8sM0afWpcMeta?.alias || [],
    redirect: heritageP8sM0afWpcMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/heritage.vue").then(m => m.default || m)
  },
  {
    name: heritageP8sM0afWpcMeta?.name ?? "about-heritage___zh",
    path: heritageP8sM0afWpcMeta?.path ?? "/zh/guanyu/yichan",
    meta: heritageP8sM0afWpcMeta || {},
    alias: heritageP8sM0afWpcMeta?.alias || [],
    redirect: heritageP8sM0afWpcMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/heritage.vue").then(m => m.default || m)
  },
  {
    name: our_45groupMEp6Iht8sfMeta?.name ?? "about-our-group___de",
    path: our_45groupMEp6Iht8sfMeta?.path ?? "/de/um/unsere-gruppe",
    meta: our_45groupMEp6Iht8sfMeta || {},
    alias: our_45groupMEp6Iht8sfMeta?.alias || [],
    redirect: our_45groupMEp6Iht8sfMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/our-group.vue").then(m => m.default || m)
  },
  {
    name: our_45groupMEp6Iht8sfMeta?.name ?? "about-our-group___en___default",
    path: our_45groupMEp6Iht8sfMeta?.path ?? "/about/our-group",
    meta: our_45groupMEp6Iht8sfMeta || {},
    alias: our_45groupMEp6Iht8sfMeta?.alias || [],
    redirect: our_45groupMEp6Iht8sfMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/our-group.vue").then(m => m.default || m)
  },
  {
    name: our_45groupMEp6Iht8sfMeta?.name ?? "about-our-group___en",
    path: our_45groupMEp6Iht8sfMeta?.path ?? "/en/about/our-group",
    meta: our_45groupMEp6Iht8sfMeta || {},
    alias: our_45groupMEp6Iht8sfMeta?.alias || [],
    redirect: our_45groupMEp6Iht8sfMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/our-group.vue").then(m => m.default || m)
  },
  {
    name: our_45groupMEp6Iht8sfMeta?.name ?? "about-our-group___it",
    path: our_45groupMEp6Iht8sfMeta?.path ?? "/it/about/il-gruppo",
    meta: our_45groupMEp6Iht8sfMeta || {},
    alias: our_45groupMEp6Iht8sfMeta?.alias || [],
    redirect: our_45groupMEp6Iht8sfMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/our-group.vue").then(m => m.default || m)
  },
  {
    name: our_45groupMEp6Iht8sfMeta?.name ?? "about-our-group___zh",
    path: our_45groupMEp6Iht8sfMeta?.path ?? "/zh/guanyu/women-de-zu",
    meta: our_45groupMEp6Iht8sfMeta || {},
    alias: our_45groupMEp6Iht8sfMeta?.alias || [],
    redirect: our_45groupMEp6Iht8sfMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/our-group.vue").then(m => m.default || m)
  },
  {
    name: partnersbJeTGd5ZpdMeta?.name ?? "about-partners___de",
    path: partnersbJeTGd5ZpdMeta?.path ?? "/de/about/partners",
    meta: partnersbJeTGd5ZpdMeta || {},
    alias: partnersbJeTGd5ZpdMeta?.alias || [],
    redirect: partnersbJeTGd5ZpdMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/partners.vue").then(m => m.default || m)
  },
  {
    name: partnersbJeTGd5ZpdMeta?.name ?? "about-partners___en___default",
    path: partnersbJeTGd5ZpdMeta?.path ?? "/about/partners",
    meta: partnersbJeTGd5ZpdMeta || {},
    alias: partnersbJeTGd5ZpdMeta?.alias || [],
    redirect: partnersbJeTGd5ZpdMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/partners.vue").then(m => m.default || m)
  },
  {
    name: partnersbJeTGd5ZpdMeta?.name ?? "about-partners___en",
    path: partnersbJeTGd5ZpdMeta?.path ?? "/en/about/partners",
    meta: partnersbJeTGd5ZpdMeta || {},
    alias: partnersbJeTGd5ZpdMeta?.alias || [],
    redirect: partnersbJeTGd5ZpdMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/partners.vue").then(m => m.default || m)
  },
  {
    name: partnersbJeTGd5ZpdMeta?.name ?? "about-partners___it",
    path: partnersbJeTGd5ZpdMeta?.path ?? "/it/about/partners",
    meta: partnersbJeTGd5ZpdMeta || {},
    alias: partnersbJeTGd5ZpdMeta?.alias || [],
    redirect: partnersbJeTGd5ZpdMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/partners.vue").then(m => m.default || m)
  },
  {
    name: partnersbJeTGd5ZpdMeta?.name ?? "about-partners___zh",
    path: partnersbJeTGd5ZpdMeta?.path ?? "/zh/about/partners",
    meta: partnersbJeTGd5ZpdMeta || {},
    alias: partnersbJeTGd5ZpdMeta?.alias || [],
    redirect: partnersbJeTGd5ZpdMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/partners.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityVt2EP7wLnlMeta?.name ?? "about-sustainability___de",
    path: sustainabilityVt2EP7wLnlMeta?.path ?? "/de/um/nachhaltigkeit",
    meta: sustainabilityVt2EP7wLnlMeta || {},
    alias: sustainabilityVt2EP7wLnlMeta?.alias || [],
    redirect: sustainabilityVt2EP7wLnlMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityVt2EP7wLnlMeta?.name ?? "about-sustainability___en___default",
    path: sustainabilityVt2EP7wLnlMeta?.path ?? "/about/sustainability",
    meta: sustainabilityVt2EP7wLnlMeta || {},
    alias: sustainabilityVt2EP7wLnlMeta?.alias || [],
    redirect: sustainabilityVt2EP7wLnlMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityVt2EP7wLnlMeta?.name ?? "about-sustainability___en",
    path: sustainabilityVt2EP7wLnlMeta?.path ?? "/en/about/sustainability",
    meta: sustainabilityVt2EP7wLnlMeta || {},
    alias: sustainabilityVt2EP7wLnlMeta?.alias || [],
    redirect: sustainabilityVt2EP7wLnlMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityVt2EP7wLnlMeta?.name ?? "about-sustainability___it",
    path: sustainabilityVt2EP7wLnlMeta?.path ?? "/it/about/sostenibilita",
    meta: sustainabilityVt2EP7wLnlMeta || {},
    alias: sustainabilityVt2EP7wLnlMeta?.alias || [],
    redirect: sustainabilityVt2EP7wLnlMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityVt2EP7wLnlMeta?.name ?? "about-sustainability___zh",
    path: sustainabilityVt2EP7wLnlMeta?.path ?? "/zh/guanyu/ke-chixu-xing",
    meta: sustainabilityVt2EP7wLnlMeta || {},
    alias: sustainabilityVt2EP7wLnlMeta?.alias || [],
    redirect: sustainabilityVt2EP7wLnlMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/sustainability.vue").then(m => m.default || m)
  },
  {
    name: teamSofY6eoDOOMeta?.name ?? "about-team___de",
    path: teamSofY6eoDOOMeta?.path ?? "/de/um/team",
    meta: teamSofY6eoDOOMeta || {},
    alias: teamSofY6eoDOOMeta?.alias || [],
    redirect: teamSofY6eoDOOMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/team.vue").then(m => m.default || m)
  },
  {
    name: teamSofY6eoDOOMeta?.name ?? "about-team___en___default",
    path: teamSofY6eoDOOMeta?.path ?? "/about/team",
    meta: teamSofY6eoDOOMeta || {},
    alias: teamSofY6eoDOOMeta?.alias || [],
    redirect: teamSofY6eoDOOMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/team.vue").then(m => m.default || m)
  },
  {
    name: teamSofY6eoDOOMeta?.name ?? "about-team___en",
    path: teamSofY6eoDOOMeta?.path ?? "/en/about/team",
    meta: teamSofY6eoDOOMeta || {},
    alias: teamSofY6eoDOOMeta?.alias || [],
    redirect: teamSofY6eoDOOMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/team.vue").then(m => m.default || m)
  },
  {
    name: teamSofY6eoDOOMeta?.name ?? "about-team___it",
    path: teamSofY6eoDOOMeta?.path ?? "/it/about/team",
    meta: teamSofY6eoDOOMeta || {},
    alias: teamSofY6eoDOOMeta?.alias || [],
    redirect: teamSofY6eoDOOMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/team.vue").then(m => m.default || m)
  },
  {
    name: teamSofY6eoDOOMeta?.name ?? "about-team___zh",
    path: teamSofY6eoDOOMeta?.path ?? "/zh/guanyu/tuandui",
    meta: teamSofY6eoDOOMeta || {},
    alias: teamSofY6eoDOOMeta?.alias || [],
    redirect: teamSofY6eoDOOMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/team.vue").then(m => m.default || m)
  },
  {
    name: visit_45pininfarinaHNJB8Tv3dBMeta?.name ?? "about-visit-pininfarina___de",
    path: visit_45pininfarinaHNJB8Tv3dBMeta?.path ?? "/de/um/besuchen-sie-pininfarina",
    meta: visit_45pininfarinaHNJB8Tv3dBMeta || {},
    alias: visit_45pininfarinaHNJB8Tv3dBMeta?.alias || [],
    redirect: visit_45pininfarinaHNJB8Tv3dBMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/visit-pininfarina.vue").then(m => m.default || m)
  },
  {
    name: visit_45pininfarinaHNJB8Tv3dBMeta?.name ?? "about-visit-pininfarina___en___default",
    path: visit_45pininfarinaHNJB8Tv3dBMeta?.path ?? "/about/visit-pininfarina",
    meta: visit_45pininfarinaHNJB8Tv3dBMeta || {},
    alias: visit_45pininfarinaHNJB8Tv3dBMeta?.alias || [],
    redirect: visit_45pininfarinaHNJB8Tv3dBMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/visit-pininfarina.vue").then(m => m.default || m)
  },
  {
    name: visit_45pininfarinaHNJB8Tv3dBMeta?.name ?? "about-visit-pininfarina___en",
    path: visit_45pininfarinaHNJB8Tv3dBMeta?.path ?? "/en/about/visit-pininfarina",
    meta: visit_45pininfarinaHNJB8Tv3dBMeta || {},
    alias: visit_45pininfarinaHNJB8Tv3dBMeta?.alias || [],
    redirect: visit_45pininfarinaHNJB8Tv3dBMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/visit-pininfarina.vue").then(m => m.default || m)
  },
  {
    name: visit_45pininfarinaHNJB8Tv3dBMeta?.name ?? "about-visit-pininfarina___it",
    path: visit_45pininfarinaHNJB8Tv3dBMeta?.path ?? "/it/about/visita-pininfarina",
    meta: visit_45pininfarinaHNJB8Tv3dBMeta || {},
    alias: visit_45pininfarinaHNJB8Tv3dBMeta?.alias || [],
    redirect: visit_45pininfarinaHNJB8Tv3dBMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/visit-pininfarina.vue").then(m => m.default || m)
  },
  {
    name: visit_45pininfarinaHNJB8Tv3dBMeta?.name ?? "about-visit-pininfarina___zh",
    path: visit_45pininfarinaHNJB8Tv3dBMeta?.path ?? "/zh/guanyu/hui-pininfarina",
    meta: visit_45pininfarinaHNJB8Tv3dBMeta || {},
    alias: visit_45pininfarinaHNJB8Tv3dBMeta?.alias || [],
    redirect: visit_45pininfarinaHNJB8Tv3dBMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/about/visit-pininfarina.vue").then(m => m.default || m)
  },
  {
    name: methodology8d4Psp2WqRMeta?.name ?? "approach-methodology___de",
    path: methodology8d4Psp2WqRMeta?.path ?? "/de/approach/methodology",
    meta: methodology8d4Psp2WqRMeta || {},
    alias: methodology8d4Psp2WqRMeta?.alias || [],
    redirect: methodology8d4Psp2WqRMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/approach/methodology.vue").then(m => m.default || m)
  },
  {
    name: methodology8d4Psp2WqRMeta?.name ?? "approach-methodology___en___default",
    path: methodology8d4Psp2WqRMeta?.path ?? "/approach/methodology",
    meta: methodology8d4Psp2WqRMeta || {},
    alias: methodology8d4Psp2WqRMeta?.alias || [],
    redirect: methodology8d4Psp2WqRMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/approach/methodology.vue").then(m => m.default || m)
  },
  {
    name: methodology8d4Psp2WqRMeta?.name ?? "approach-methodology___en",
    path: methodology8d4Psp2WqRMeta?.path ?? "/en/approach/methodology",
    meta: methodology8d4Psp2WqRMeta || {},
    alias: methodology8d4Psp2WqRMeta?.alias || [],
    redirect: methodology8d4Psp2WqRMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/approach/methodology.vue").then(m => m.default || m)
  },
  {
    name: methodology8d4Psp2WqRMeta?.name ?? "approach-methodology___it",
    path: methodology8d4Psp2WqRMeta?.path ?? "/it/approach/methodology",
    meta: methodology8d4Psp2WqRMeta || {},
    alias: methodology8d4Psp2WqRMeta?.alias || [],
    redirect: methodology8d4Psp2WqRMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/approach/methodology.vue").then(m => m.default || m)
  },
  {
    name: methodology8d4Psp2WqRMeta?.name ?? "approach-methodology___zh",
    path: methodology8d4Psp2WqRMeta?.path ?? "/zh/approach/methodology",
    meta: methodology8d4Psp2WqRMeta || {},
    alias: methodology8d4Psp2WqRMeta?.alias || [],
    redirect: methodology8d4Psp2WqRMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/approach/methodology.vue").then(m => m.default || m)
  },
  {
    name: _91jobSlug_93ZQjYgoR1X3Meta?.name ?? "careers-jobSlug___de",
    path: _91jobSlug_93ZQjYgoR1X3Meta?.path ?? "/de/karriere/:jobSlug",
    meta: _91jobSlug_93ZQjYgoR1X3Meta || {},
    alias: _91jobSlug_93ZQjYgoR1X3Meta?.alias || [],
    redirect: _91jobSlug_93ZQjYgoR1X3Meta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/careers/[jobSlug].vue").then(m => m.default || m)
  },
  {
    name: _91jobSlug_93ZQjYgoR1X3Meta?.name ?? "careers-jobSlug___en___default",
    path: _91jobSlug_93ZQjYgoR1X3Meta?.path ?? "/careers/:jobSlug",
    meta: _91jobSlug_93ZQjYgoR1X3Meta || {},
    alias: _91jobSlug_93ZQjYgoR1X3Meta?.alias || [],
    redirect: _91jobSlug_93ZQjYgoR1X3Meta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/careers/[jobSlug].vue").then(m => m.default || m)
  },
  {
    name: _91jobSlug_93ZQjYgoR1X3Meta?.name ?? "careers-jobSlug___en",
    path: _91jobSlug_93ZQjYgoR1X3Meta?.path ?? "/en/careers/:jobSlug",
    meta: _91jobSlug_93ZQjYgoR1X3Meta || {},
    alias: _91jobSlug_93ZQjYgoR1X3Meta?.alias || [],
    redirect: _91jobSlug_93ZQjYgoR1X3Meta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/careers/[jobSlug].vue").then(m => m.default || m)
  },
  {
    name: _91jobSlug_93ZQjYgoR1X3Meta?.name ?? "careers-jobSlug___it",
    path: _91jobSlug_93ZQjYgoR1X3Meta?.path ?? "/it/carriere/:jobSlug",
    meta: _91jobSlug_93ZQjYgoR1X3Meta || {},
    alias: _91jobSlug_93ZQjYgoR1X3Meta?.alias || [],
    redirect: _91jobSlug_93ZQjYgoR1X3Meta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/careers/[jobSlug].vue").then(m => m.default || m)
  },
  {
    name: _91jobSlug_93ZQjYgoR1X3Meta?.name ?? "careers-jobSlug___zh",
    path: _91jobSlug_93ZQjYgoR1X3Meta?.path ?? "/zh/zhiye-shengya/:jobSlug",
    meta: _91jobSlug_93ZQjYgoR1X3Meta || {},
    alias: _91jobSlug_93ZQjYgoR1X3Meta?.alias || [],
    redirect: _91jobSlug_93ZQjYgoR1X3Meta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/careers/[jobSlug].vue").then(m => m.default || m)
  },
  {
    name: indexw5YMIyVtfpMeta?.name ?? "careers___de",
    path: indexw5YMIyVtfpMeta?.path ?? "/de/karriere",
    meta: indexw5YMIyVtfpMeta || {},
    alias: indexw5YMIyVtfpMeta?.alias || [],
    redirect: indexw5YMIyVtfpMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexw5YMIyVtfpMeta?.name ?? "careers___en___default",
    path: indexw5YMIyVtfpMeta?.path ?? "/careers/",
    meta: indexw5YMIyVtfpMeta || {},
    alias: indexw5YMIyVtfpMeta?.alias || [],
    redirect: indexw5YMIyVtfpMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexw5YMIyVtfpMeta?.name ?? "careers___en",
    path: indexw5YMIyVtfpMeta?.path ?? "/en/careers",
    meta: indexw5YMIyVtfpMeta || {},
    alias: indexw5YMIyVtfpMeta?.alias || [],
    redirect: indexw5YMIyVtfpMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexw5YMIyVtfpMeta?.name ?? "careers___it",
    path: indexw5YMIyVtfpMeta?.path ?? "/it/carriere",
    meta: indexw5YMIyVtfpMeta || {},
    alias: indexw5YMIyVtfpMeta?.alias || [],
    redirect: indexw5YMIyVtfpMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexw5YMIyVtfpMeta?.name ?? "careers___zh",
    path: indexw5YMIyVtfpMeta?.path ?? "/zh/zhiye-shengya",
    meta: indexw5YMIyVtfpMeta || {},
    alias: indexw5YMIyVtfpMeta?.alias || [],
    redirect: indexw5YMIyVtfpMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexbxGv1VpawRMeta?.name ?? "classiche___de",
    path: indexbxGv1VpawRMeta?.path ?? "/de/classiche",
    meta: indexbxGv1VpawRMeta || {},
    alias: indexbxGv1VpawRMeta?.alias || [],
    redirect: indexbxGv1VpawRMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/classiche/index.vue").then(m => m.default || m)
  },
  {
    name: indexbxGv1VpawRMeta?.name ?? "classiche___en___default",
    path: indexbxGv1VpawRMeta?.path ?? "/classiche/",
    meta: indexbxGv1VpawRMeta || {},
    alias: indexbxGv1VpawRMeta?.alias || [],
    redirect: indexbxGv1VpawRMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/classiche/index.vue").then(m => m.default || m)
  },
  {
    name: indexbxGv1VpawRMeta?.name ?? "classiche___en",
    path: indexbxGv1VpawRMeta?.path ?? "/en/classiche",
    meta: indexbxGv1VpawRMeta || {},
    alias: indexbxGv1VpawRMeta?.alias || [],
    redirect: indexbxGv1VpawRMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/classiche/index.vue").then(m => m.default || m)
  },
  {
    name: indexbxGv1VpawRMeta?.name ?? "classiche___it",
    path: indexbxGv1VpawRMeta?.path ?? "/it/classiche",
    meta: indexbxGv1VpawRMeta || {},
    alias: indexbxGv1VpawRMeta?.alias || [],
    redirect: indexbxGv1VpawRMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/classiche/index.vue").then(m => m.default || m)
  },
  {
    name: indexbxGv1VpawRMeta?.name ?? "classiche___zh",
    path: indexbxGv1VpawRMeta?.path ?? "/zh/classiche",
    meta: indexbxGv1VpawRMeta || {},
    alias: indexbxGv1VpawRMeta?.alias || [],
    redirect: indexbxGv1VpawRMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/classiche/index.vue").then(m => m.default || m)
  },
  {
    name: _91countrySlug_93IE8xQYVEX5Meta?.name ?? "contacts-countrySlug___de",
    path: _91countrySlug_93IE8xQYVEX5Meta?.path ?? "/de/kontakte/:countrySlug",
    meta: _91countrySlug_93IE8xQYVEX5Meta || {},
    alias: _91countrySlug_93IE8xQYVEX5Meta?.alias || [],
    redirect: _91countrySlug_93IE8xQYVEX5Meta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/contacts/[countrySlug].vue").then(m => m.default || m)
  },
  {
    name: _91countrySlug_93IE8xQYVEX5Meta?.name ?? "contacts-countrySlug___en___default",
    path: _91countrySlug_93IE8xQYVEX5Meta?.path ?? "/contacts/:countrySlug",
    meta: _91countrySlug_93IE8xQYVEX5Meta || {},
    alias: _91countrySlug_93IE8xQYVEX5Meta?.alias || [],
    redirect: _91countrySlug_93IE8xQYVEX5Meta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/contacts/[countrySlug].vue").then(m => m.default || m)
  },
  {
    name: _91countrySlug_93IE8xQYVEX5Meta?.name ?? "contacts-countrySlug___en",
    path: _91countrySlug_93IE8xQYVEX5Meta?.path ?? "/en/contacts/:countrySlug",
    meta: _91countrySlug_93IE8xQYVEX5Meta || {},
    alias: _91countrySlug_93IE8xQYVEX5Meta?.alias || [],
    redirect: _91countrySlug_93IE8xQYVEX5Meta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/contacts/[countrySlug].vue").then(m => m.default || m)
  },
  {
    name: _91countrySlug_93IE8xQYVEX5Meta?.name ?? "contacts-countrySlug___it",
    path: _91countrySlug_93IE8xQYVEX5Meta?.path ?? "/it/contatti/:countrySlug",
    meta: _91countrySlug_93IE8xQYVEX5Meta || {},
    alias: _91countrySlug_93IE8xQYVEX5Meta?.alias || [],
    redirect: _91countrySlug_93IE8xQYVEX5Meta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/contacts/[countrySlug].vue").then(m => m.default || m)
  },
  {
    name: _91countrySlug_93IE8xQYVEX5Meta?.name ?? "contacts-countrySlug___zh",
    path: _91countrySlug_93IE8xQYVEX5Meta?.path ?? "/zh/lianxi-ren/:countrySlug",
    meta: _91countrySlug_93IE8xQYVEX5Meta || {},
    alias: _91countrySlug_93IE8xQYVEX5Meta?.alias || [],
    redirect: _91countrySlug_93IE8xQYVEX5Meta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/contacts/[countrySlug].vue").then(m => m.default || m)
  },
  {
    name: indexM0DR6nj1oZMeta?.name ?? "contacts___de",
    path: indexM0DR6nj1oZMeta?.path ?? "/de/kontakte/unsere-gruppe",
    meta: indexM0DR6nj1oZMeta || {},
    alias: indexM0DR6nj1oZMeta?.alias || [],
    redirect: indexM0DR6nj1oZMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexM0DR6nj1oZMeta?.name ?? "contacts___en___default",
    path: indexM0DR6nj1oZMeta?.path ?? "/contacts/our-offices",
    meta: indexM0DR6nj1oZMeta || {},
    alias: indexM0DR6nj1oZMeta?.alias || [],
    redirect: indexM0DR6nj1oZMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexM0DR6nj1oZMeta?.name ?? "contacts___en",
    path: indexM0DR6nj1oZMeta?.path ?? "/en/contacts/our-offices",
    meta: indexM0DR6nj1oZMeta || {},
    alias: indexM0DR6nj1oZMeta?.alias || [],
    redirect: indexM0DR6nj1oZMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexM0DR6nj1oZMeta?.name ?? "contacts___it",
    path: indexM0DR6nj1oZMeta?.path ?? "/it/contatti/i-nostri-uffici",
    meta: indexM0DR6nj1oZMeta || {},
    alias: indexM0DR6nj1oZMeta?.alias || [],
    redirect: indexM0DR6nj1oZMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexM0DR6nj1oZMeta?.name ?? "contacts___zh",
    path: indexM0DR6nj1oZMeta?.path ?? "/zh/lianxi-ren/women-de-zu",
    meta: indexM0DR6nj1oZMeta || {},
    alias: indexM0DR6nj1oZMeta?.alias || [],
    redirect: indexM0DR6nj1oZMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: messageSentmbpSCELYufMeta?.name ?? "contacts-messageSent___de",
    path: messageSentmbpSCELYufMeta?.path ?? "/de/message-sent",
    meta: messageSentmbpSCELYufMeta || {},
    alias: messageSentmbpSCELYufMeta?.alias || [],
    redirect: messageSentmbpSCELYufMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/contacts/messageSent.vue").then(m => m.default || m)
  },
  {
    name: messageSentmbpSCELYufMeta?.name ?? "contacts-messageSent___en___default",
    path: messageSentmbpSCELYufMeta?.path ?? "/message-sent",
    meta: messageSentmbpSCELYufMeta || {},
    alias: messageSentmbpSCELYufMeta?.alias || [],
    redirect: messageSentmbpSCELYufMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/contacts/messageSent.vue").then(m => m.default || m)
  },
  {
    name: messageSentmbpSCELYufMeta?.name ?? "contacts-messageSent___en",
    path: messageSentmbpSCELYufMeta?.path ?? "/en/message-sent",
    meta: messageSentmbpSCELYufMeta || {},
    alias: messageSentmbpSCELYufMeta?.alias || [],
    redirect: messageSentmbpSCELYufMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/contacts/messageSent.vue").then(m => m.default || m)
  },
  {
    name: messageSentmbpSCELYufMeta?.name ?? "contacts-messageSent___it",
    path: messageSentmbpSCELYufMeta?.path ?? "/it/message-sent",
    meta: messageSentmbpSCELYufMeta || {},
    alias: messageSentmbpSCELYufMeta?.alias || [],
    redirect: messageSentmbpSCELYufMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/contacts/messageSent.vue").then(m => m.default || m)
  },
  {
    name: messageSentmbpSCELYufMeta?.name ?? "contacts-messageSent___zh",
    path: messageSentmbpSCELYufMeta?.path ?? "/zh/message-sent",
    meta: messageSentmbpSCELYufMeta || {},
    alias: messageSentmbpSCELYufMeta?.alias || [],
    redirect: messageSentmbpSCELYufMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/contacts/messageSent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyPF4ZbYdoxlMeta?.name ?? "cookie-policy___de",
    path: cookie_45policyPF4ZbYdoxlMeta?.path ?? "/de/cookie-policy",
    meta: cookie_45policyPF4ZbYdoxlMeta || {},
    alias: cookie_45policyPF4ZbYdoxlMeta?.alias || [],
    redirect: cookie_45policyPF4ZbYdoxlMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyPF4ZbYdoxlMeta?.name ?? "cookie-policy___en___default",
    path: cookie_45policyPF4ZbYdoxlMeta?.path ?? "/cookie-policy/",
    meta: cookie_45policyPF4ZbYdoxlMeta || {},
    alias: cookie_45policyPF4ZbYdoxlMeta?.alias || [],
    redirect: cookie_45policyPF4ZbYdoxlMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyPF4ZbYdoxlMeta?.name ?? "cookie-policy___en",
    path: cookie_45policyPF4ZbYdoxlMeta?.path ?? "/en/cookie-policy",
    meta: cookie_45policyPF4ZbYdoxlMeta || {},
    alias: cookie_45policyPF4ZbYdoxlMeta?.alias || [],
    redirect: cookie_45policyPF4ZbYdoxlMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyPF4ZbYdoxlMeta?.name ?? "cookie-policy___it",
    path: cookie_45policyPF4ZbYdoxlMeta?.path ?? "/it/cookie-policy",
    meta: cookie_45policyPF4ZbYdoxlMeta || {},
    alias: cookie_45policyPF4ZbYdoxlMeta?.alias || [],
    redirect: cookie_45policyPF4ZbYdoxlMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyPF4ZbYdoxlMeta?.name ?? "cookie-policy___zh",
    path: cookie_45policyPF4ZbYdoxlMeta?.path ?? "/zh/cookie-policy",
    meta: cookie_45policyPF4ZbYdoxlMeta || {},
    alias: cookie_45policyPF4ZbYdoxlMeta?.alias || [],
    redirect: cookie_45policyPF4ZbYdoxlMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: indexl4wm0xeLuKMeta?.name ?? "index___de",
    path: indexl4wm0xeLuKMeta?.path ?? "/de",
    meta: indexl4wm0xeLuKMeta || {},
    alias: indexl4wm0xeLuKMeta?.alias || [],
    redirect: indexl4wm0xeLuKMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexl4wm0xeLuKMeta?.name ?? "index___en___default",
    path: indexl4wm0xeLuKMeta?.path ?? "/",
    meta: indexl4wm0xeLuKMeta || {},
    alias: indexl4wm0xeLuKMeta?.alias || [],
    redirect: indexl4wm0xeLuKMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexl4wm0xeLuKMeta?.name ?? "index___en",
    path: indexl4wm0xeLuKMeta?.path ?? "/en",
    meta: indexl4wm0xeLuKMeta || {},
    alias: indexl4wm0xeLuKMeta?.alias || [],
    redirect: indexl4wm0xeLuKMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexl4wm0xeLuKMeta?.name ?? "index___it",
    path: indexl4wm0xeLuKMeta?.path ?? "/it",
    meta: indexl4wm0xeLuKMeta || {},
    alias: indexl4wm0xeLuKMeta?.alias || [],
    redirect: indexl4wm0xeLuKMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexl4wm0xeLuKMeta?.name ?? "index___zh",
    path: indexl4wm0xeLuKMeta?.path ?? "/zh",
    meta: indexl4wm0xeLuKMeta || {},
    alias: indexl4wm0xeLuKMeta?.alias || [],
    redirect: indexl4wm0xeLuKMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexMuVk1mrFjSMeta?.name ?? "investor-relations-investorSlug___de",
    path: indexMuVk1mrFjSMeta?.path ?? "/de/investor-relations/:investorSlug?",
    meta: indexMuVk1mrFjSMeta || {},
    alias: indexMuVk1mrFjSMeta?.alias || [],
    redirect: indexMuVk1mrFjSMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/investor-relations/[[investorSlug]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMuVk1mrFjSMeta?.name ?? "investor-relations-investorSlug___en___default",
    path: indexMuVk1mrFjSMeta?.path ?? "/investor-relations/:investorSlug?",
    meta: indexMuVk1mrFjSMeta || {},
    alias: indexMuVk1mrFjSMeta?.alias || [],
    redirect: indexMuVk1mrFjSMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/investor-relations/[[investorSlug]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMuVk1mrFjSMeta?.name ?? "investor-relations-investorSlug___en",
    path: indexMuVk1mrFjSMeta?.path ?? "/en/investor-relations/:investorSlug?",
    meta: indexMuVk1mrFjSMeta || {},
    alias: indexMuVk1mrFjSMeta?.alias || [],
    redirect: indexMuVk1mrFjSMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/investor-relations/[[investorSlug]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMuVk1mrFjSMeta?.name ?? "investor-relations-investorSlug___it",
    path: indexMuVk1mrFjSMeta?.path ?? "/it/investor-relations/:investorSlug?",
    meta: indexMuVk1mrFjSMeta || {},
    alias: indexMuVk1mrFjSMeta?.alias || [],
    redirect: indexMuVk1mrFjSMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/investor-relations/[[investorSlug]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMuVk1mrFjSMeta?.name ?? "investor-relations-investorSlug___zh",
    path: indexMuVk1mrFjSMeta?.path ?? "/zh/investor-relations/:investorSlug?",
    meta: indexMuVk1mrFjSMeta || {},
    alias: indexMuVk1mrFjSMeta?.alias || [],
    redirect: indexMuVk1mrFjSMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/investor-relations/[[investorSlug]]/index.vue").then(m => m.default || m)
  },
  {
    name: hq_45resourcesJq7nSpnNEoMeta?.name ?? "media-hub-hq-resources___de",
    path: hq_45resourcesJq7nSpnNEoMeta?.path ?? "/de/media-hub/hq-resources",
    meta: hq_45resourcesJq7nSpnNEoMeta || {},
    alias: hq_45resourcesJq7nSpnNEoMeta?.alias || [],
    redirect: hq_45resourcesJq7nSpnNEoMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/media-hub/hq-resources.vue").then(m => m.default || m)
  },
  {
    name: hq_45resourcesJq7nSpnNEoMeta?.name ?? "media-hub-hq-resources___en___default",
    path: hq_45resourcesJq7nSpnNEoMeta?.path ?? "/media-hub/hq-resources",
    meta: hq_45resourcesJq7nSpnNEoMeta || {},
    alias: hq_45resourcesJq7nSpnNEoMeta?.alias || [],
    redirect: hq_45resourcesJq7nSpnNEoMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/media-hub/hq-resources.vue").then(m => m.default || m)
  },
  {
    name: hq_45resourcesJq7nSpnNEoMeta?.name ?? "media-hub-hq-resources___en",
    path: hq_45resourcesJq7nSpnNEoMeta?.path ?? "/en/media-hub/hq-resources",
    meta: hq_45resourcesJq7nSpnNEoMeta || {},
    alias: hq_45resourcesJq7nSpnNEoMeta?.alias || [],
    redirect: hq_45resourcesJq7nSpnNEoMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/media-hub/hq-resources.vue").then(m => m.default || m)
  },
  {
    name: hq_45resourcesJq7nSpnNEoMeta?.name ?? "media-hub-hq-resources___it",
    path: hq_45resourcesJq7nSpnNEoMeta?.path ?? "/it/media-hub/hq-resources",
    meta: hq_45resourcesJq7nSpnNEoMeta || {},
    alias: hq_45resourcesJq7nSpnNEoMeta?.alias || [],
    redirect: hq_45resourcesJq7nSpnNEoMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/media-hub/hq-resources.vue").then(m => m.default || m)
  },
  {
    name: hq_45resourcesJq7nSpnNEoMeta?.name ?? "media-hub-hq-resources___zh",
    path: hq_45resourcesJq7nSpnNEoMeta?.path ?? "/zh/media-hub/hq-resources",
    meta: hq_45resourcesJq7nSpnNEoMeta || {},
    alias: hq_45resourcesJq7nSpnNEoMeta?.alias || [],
    redirect: hq_45resourcesJq7nSpnNEoMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/media-hub/hq-resources.vue").then(m => m.default || m)
  },
  {
    name: indexoVMKatJeNhMeta?.name ?? "media-hub___de",
    path: indexoVMKatJeNhMeta?.path ?? "/de/media-hub",
    meta: indexoVMKatJeNhMeta || {},
    alias: indexoVMKatJeNhMeta?.alias || [],
    redirect: indexoVMKatJeNhMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/media-hub/index.vue").then(m => m.default || m)
  },
  {
    name: indexoVMKatJeNhMeta?.name ?? "media-hub___en___default",
    path: indexoVMKatJeNhMeta?.path ?? "/media-hub/",
    meta: indexoVMKatJeNhMeta || {},
    alias: indexoVMKatJeNhMeta?.alias || [],
    redirect: indexoVMKatJeNhMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/media-hub/index.vue").then(m => m.default || m)
  },
  {
    name: indexoVMKatJeNhMeta?.name ?? "media-hub___en",
    path: indexoVMKatJeNhMeta?.path ?? "/en/media-hub",
    meta: indexoVMKatJeNhMeta || {},
    alias: indexoVMKatJeNhMeta?.alias || [],
    redirect: indexoVMKatJeNhMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/media-hub/index.vue").then(m => m.default || m)
  },
  {
    name: indexoVMKatJeNhMeta?.name ?? "media-hub___it",
    path: indexoVMKatJeNhMeta?.path ?? "/it/media-hub",
    meta: indexoVMKatJeNhMeta || {},
    alias: indexoVMKatJeNhMeta?.alias || [],
    redirect: indexoVMKatJeNhMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/media-hub/index.vue").then(m => m.default || m)
  },
  {
    name: indexoVMKatJeNhMeta?.name ?? "media-hub___zh",
    path: indexoVMKatJeNhMeta?.path ?? "/zh/media-hub",
    meta: indexoVMKatJeNhMeta || {},
    alias: indexoVMKatJeNhMeta?.alias || [],
    redirect: indexoVMKatJeNhMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/media-hub/index.vue").then(m => m.default || m)
  },
  {
    name: loginGFlNoJq8Z6Meta?.name ?? "media-hub-login___de",
    path: loginGFlNoJq8Z6Meta?.path ?? "/de/media-hub/login",
    meta: loginGFlNoJq8Z6Meta || {},
    alias: loginGFlNoJq8Z6Meta?.alias || [],
    redirect: loginGFlNoJq8Z6Meta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/media-hub/login.vue").then(m => m.default || m)
  },
  {
    name: loginGFlNoJq8Z6Meta?.name ?? "media-hub-login___en___default",
    path: loginGFlNoJq8Z6Meta?.path ?? "/media-hub/login",
    meta: loginGFlNoJq8Z6Meta || {},
    alias: loginGFlNoJq8Z6Meta?.alias || [],
    redirect: loginGFlNoJq8Z6Meta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/media-hub/login.vue").then(m => m.default || m)
  },
  {
    name: loginGFlNoJq8Z6Meta?.name ?? "media-hub-login___en",
    path: loginGFlNoJq8Z6Meta?.path ?? "/en/media-hub/login",
    meta: loginGFlNoJq8Z6Meta || {},
    alias: loginGFlNoJq8Z6Meta?.alias || [],
    redirect: loginGFlNoJq8Z6Meta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/media-hub/login.vue").then(m => m.default || m)
  },
  {
    name: loginGFlNoJq8Z6Meta?.name ?? "media-hub-login___it",
    path: loginGFlNoJq8Z6Meta?.path ?? "/it/media-hub/login",
    meta: loginGFlNoJq8Z6Meta || {},
    alias: loginGFlNoJq8Z6Meta?.alias || [],
    redirect: loginGFlNoJq8Z6Meta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/media-hub/login.vue").then(m => m.default || m)
  },
  {
    name: loginGFlNoJq8Z6Meta?.name ?? "media-hub-login___zh",
    path: loginGFlNoJq8Z6Meta?.path ?? "/zh/media-hub/login",
    meta: loginGFlNoJq8Z6Meta || {},
    alias: loginGFlNoJq8Z6Meta?.alias || [],
    redirect: loginGFlNoJq8Z6Meta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/media-hub/login.vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_93AmB5wLiYAyMeta?.name ?? "news-newsSlug___de",
    path: _91newsSlug_93AmB5wLiYAyMeta?.path ?? "/de/nachrichten/:newsSlug",
    meta: _91newsSlug_93AmB5wLiYAyMeta || {},
    alias: _91newsSlug_93AmB5wLiYAyMeta?.alias || [],
    redirect: _91newsSlug_93AmB5wLiYAyMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_93AmB5wLiYAyMeta?.name ?? "news-newsSlug___en___default",
    path: _91newsSlug_93AmB5wLiYAyMeta?.path ?? "/news/:newsSlug",
    meta: _91newsSlug_93AmB5wLiYAyMeta || {},
    alias: _91newsSlug_93AmB5wLiYAyMeta?.alias || [],
    redirect: _91newsSlug_93AmB5wLiYAyMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_93AmB5wLiYAyMeta?.name ?? "news-newsSlug___en",
    path: _91newsSlug_93AmB5wLiYAyMeta?.path ?? "/en/news/:newsSlug",
    meta: _91newsSlug_93AmB5wLiYAyMeta || {},
    alias: _91newsSlug_93AmB5wLiYAyMeta?.alias || [],
    redirect: _91newsSlug_93AmB5wLiYAyMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_93AmB5wLiYAyMeta?.name ?? "news-newsSlug___it",
    path: _91newsSlug_93AmB5wLiYAyMeta?.path ?? "/it/notizie/:newsSlug",
    meta: _91newsSlug_93AmB5wLiYAyMeta || {},
    alias: _91newsSlug_93AmB5wLiYAyMeta?.alias || [],
    redirect: _91newsSlug_93AmB5wLiYAyMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: _91newsSlug_93AmB5wLiYAyMeta?.name ?? "news-newsSlug___zh",
    path: _91newsSlug_93AmB5wLiYAyMeta?.path ?? "/zh/xinwen/:newsSlug",
    meta: _91newsSlug_93AmB5wLiYAyMeta || {},
    alias: _91newsSlug_93AmB5wLiYAyMeta?.alias || [],
    redirect: _91newsSlug_93AmB5wLiYAyMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/news/[newsSlug].vue").then(m => m.default || m)
  },
  {
    name: indexgQMmgeNJkqMeta?.name ?? "news___de",
    path: indexgQMmgeNJkqMeta?.path ?? "/de/nachrichten",
    meta: indexgQMmgeNJkqMeta || {},
    alias: indexgQMmgeNJkqMeta?.alias || [],
    redirect: indexgQMmgeNJkqMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexgQMmgeNJkqMeta?.name ?? "news___en___default",
    path: indexgQMmgeNJkqMeta?.path ?? "/news/",
    meta: indexgQMmgeNJkqMeta || {},
    alias: indexgQMmgeNJkqMeta?.alias || [],
    redirect: indexgQMmgeNJkqMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexgQMmgeNJkqMeta?.name ?? "news___en",
    path: indexgQMmgeNJkqMeta?.path ?? "/en/news",
    meta: indexgQMmgeNJkqMeta || {},
    alias: indexgQMmgeNJkqMeta?.alias || [],
    redirect: indexgQMmgeNJkqMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexgQMmgeNJkqMeta?.name ?? "news___it",
    path: indexgQMmgeNJkqMeta?.path ?? "/it/notizie",
    meta: indexgQMmgeNJkqMeta || {},
    alias: indexgQMmgeNJkqMeta?.alias || [],
    redirect: indexgQMmgeNJkqMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexgQMmgeNJkqMeta?.name ?? "news___zh",
    path: indexgQMmgeNJkqMeta?.path ?? "/zh/xinwen",
    meta: indexgQMmgeNJkqMeta || {},
    alias: indexgQMmgeNJkqMeta?.alias || [],
    redirect: indexgQMmgeNJkqMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: successUIIH1nefHqMeta?.name ?? "pardot-success___de",
    path: successUIIH1nefHqMeta?.path ?? "/de/pardot/success",
    meta: successUIIH1nefHqMeta || {},
    alias: successUIIH1nefHqMeta?.alias || [],
    redirect: successUIIH1nefHqMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/pardot/success.vue").then(m => m.default || m)
  },
  {
    name: successUIIH1nefHqMeta?.name ?? "pardot-success___en___default",
    path: successUIIH1nefHqMeta?.path ?? "/pardot/success",
    meta: successUIIH1nefHqMeta || {},
    alias: successUIIH1nefHqMeta?.alias || [],
    redirect: successUIIH1nefHqMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/pardot/success.vue").then(m => m.default || m)
  },
  {
    name: successUIIH1nefHqMeta?.name ?? "pardot-success___en",
    path: successUIIH1nefHqMeta?.path ?? "/en/pardot/success",
    meta: successUIIH1nefHqMeta || {},
    alias: successUIIH1nefHqMeta?.alias || [],
    redirect: successUIIH1nefHqMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/pardot/success.vue").then(m => m.default || m)
  },
  {
    name: successUIIH1nefHqMeta?.name ?? "pardot-success___it",
    path: successUIIH1nefHqMeta?.path ?? "/it/pardot/success",
    meta: successUIIH1nefHqMeta || {},
    alias: successUIIH1nefHqMeta?.alias || [],
    redirect: successUIIH1nefHqMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/pardot/success.vue").then(m => m.default || m)
  },
  {
    name: successUIIH1nefHqMeta?.name ?? "pardot-success___zh",
    path: successUIIH1nefHqMeta?.path ?? "/zh/pardot/success",
    meta: successUIIH1nefHqMeta || {},
    alias: successUIIH1nefHqMeta?.alias || [],
    redirect: successUIIH1nefHqMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/pardot/success.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policycQgRovIg7LMeta?.name ?? "privacy-policy___de",
    path: privacy_45policycQgRovIg7LMeta?.path ?? "/de/privacy-policy",
    meta: privacy_45policycQgRovIg7LMeta || {},
    alias: privacy_45policycQgRovIg7LMeta?.alias || [],
    redirect: privacy_45policycQgRovIg7LMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policycQgRovIg7LMeta?.name ?? "privacy-policy___en___default",
    path: privacy_45policycQgRovIg7LMeta?.path ?? "/privacy-policy/",
    meta: privacy_45policycQgRovIg7LMeta || {},
    alias: privacy_45policycQgRovIg7LMeta?.alias || [],
    redirect: privacy_45policycQgRovIg7LMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policycQgRovIg7LMeta?.name ?? "privacy-policy___en",
    path: privacy_45policycQgRovIg7LMeta?.path ?? "/en/privacy-policy",
    meta: privacy_45policycQgRovIg7LMeta || {},
    alias: privacy_45policycQgRovIg7LMeta?.alias || [],
    redirect: privacy_45policycQgRovIg7LMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policycQgRovIg7LMeta?.name ?? "privacy-policy___it",
    path: privacy_45policycQgRovIg7LMeta?.path ?? "/it/privacy-policy",
    meta: privacy_45policycQgRovIg7LMeta || {},
    alias: privacy_45policycQgRovIg7LMeta?.alias || [],
    redirect: privacy_45policycQgRovIg7LMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policycQgRovIg7LMeta?.name ?? "privacy-policy___zh",
    path: privacy_45policycQgRovIg7LMeta?.path ?? "/zh/privacy-policy",
    meta: privacy_45policycQgRovIg7LMeta || {},
    alias: privacy_45policycQgRovIg7LMeta?.alias || [],
    redirect: privacy_45policycQgRovIg7LMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: _91projectSlug_93Nm2WcRZ6FAMeta?.name ?? "projects-projectSlug___de",
    path: _91projectSlug_93Nm2WcRZ6FAMeta?.path ?? "/de/projekte/:projectSlug",
    meta: _91projectSlug_93Nm2WcRZ6FAMeta || {},
    alias: _91projectSlug_93Nm2WcRZ6FAMeta?.alias || [],
    redirect: _91projectSlug_93Nm2WcRZ6FAMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/projects/[projectSlug].vue").then(m => m.default || m)
  },
  {
    name: _91projectSlug_93Nm2WcRZ6FAMeta?.name ?? "projects-projectSlug___en___default",
    path: _91projectSlug_93Nm2WcRZ6FAMeta?.path ?? "/projects/:projectSlug",
    meta: _91projectSlug_93Nm2WcRZ6FAMeta || {},
    alias: _91projectSlug_93Nm2WcRZ6FAMeta?.alias || [],
    redirect: _91projectSlug_93Nm2WcRZ6FAMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/projects/[projectSlug].vue").then(m => m.default || m)
  },
  {
    name: _91projectSlug_93Nm2WcRZ6FAMeta?.name ?? "projects-projectSlug___en",
    path: _91projectSlug_93Nm2WcRZ6FAMeta?.path ?? "/en/projects/:projectSlug",
    meta: _91projectSlug_93Nm2WcRZ6FAMeta || {},
    alias: _91projectSlug_93Nm2WcRZ6FAMeta?.alias || [],
    redirect: _91projectSlug_93Nm2WcRZ6FAMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/projects/[projectSlug].vue").then(m => m.default || m)
  },
  {
    name: _91projectSlug_93Nm2WcRZ6FAMeta?.name ?? "projects-projectSlug___it",
    path: _91projectSlug_93Nm2WcRZ6FAMeta?.path ?? "/it/progetti/:projectSlug",
    meta: _91projectSlug_93Nm2WcRZ6FAMeta || {},
    alias: _91projectSlug_93Nm2WcRZ6FAMeta?.alias || [],
    redirect: _91projectSlug_93Nm2WcRZ6FAMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/projects/[projectSlug].vue").then(m => m.default || m)
  },
  {
    name: _91projectSlug_93Nm2WcRZ6FAMeta?.name ?? "projects-projectSlug___zh",
    path: _91projectSlug_93Nm2WcRZ6FAMeta?.path ?? "/zh/xiangmu/:projectSlug",
    meta: _91projectSlug_93Nm2WcRZ6FAMeta || {},
    alias: _91projectSlug_93Nm2WcRZ6FAMeta?.alias || [],
    redirect: _91projectSlug_93Nm2WcRZ6FAMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/projects/[projectSlug].vue").then(m => m.default || m)
  },
  {
    name: indexWiuHclYhjSMeta?.name ?? "search___de",
    path: indexWiuHclYhjSMeta?.path ?? "/de/suchen",
    meta: indexWiuHclYhjSMeta || {},
    alias: indexWiuHclYhjSMeta?.alias || [],
    redirect: indexWiuHclYhjSMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexWiuHclYhjSMeta?.name ?? "search___en___default",
    path: indexWiuHclYhjSMeta?.path ?? "/search/",
    meta: indexWiuHclYhjSMeta || {},
    alias: indexWiuHclYhjSMeta?.alias || [],
    redirect: indexWiuHclYhjSMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexWiuHclYhjSMeta?.name ?? "search___en",
    path: indexWiuHclYhjSMeta?.path ?? "/en/search",
    meta: indexWiuHclYhjSMeta || {},
    alias: indexWiuHclYhjSMeta?.alias || [],
    redirect: indexWiuHclYhjSMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexWiuHclYhjSMeta?.name ?? "search___it",
    path: indexWiuHclYhjSMeta?.path ?? "/it/ricerca",
    meta: indexWiuHclYhjSMeta || {},
    alias: indexWiuHclYhjSMeta?.alias || [],
    redirect: indexWiuHclYhjSMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexWiuHclYhjSMeta?.name ?? "search___zh",
    path: indexWiuHclYhjSMeta?.path ?? "/zh/sousuo",
    meta: indexWiuHclYhjSMeta || {},
    alias: indexWiuHclYhjSMeta?.alias || [],
    redirect: indexWiuHclYhjSMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexZDfgCTkpQxMeta?.name ?? "sectors-sectorSlug___de",
    path: indexZDfgCTkpQxMeta?.path ?? "/de/sektoren/:sectorSlug",
    meta: indexZDfgCTkpQxMeta || {},
    alias: indexZDfgCTkpQxMeta?.alias || [],
    redirect: indexZDfgCTkpQxMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/sectors/[sectorSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZDfgCTkpQxMeta?.name ?? "sectors-sectorSlug___en___default",
    path: indexZDfgCTkpQxMeta?.path ?? "/sectors/:sectorSlug/",
    meta: indexZDfgCTkpQxMeta || {},
    alias: indexZDfgCTkpQxMeta?.alias || [],
    redirect: indexZDfgCTkpQxMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/sectors/[sectorSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZDfgCTkpQxMeta?.name ?? "sectors-sectorSlug___en",
    path: indexZDfgCTkpQxMeta?.path ?? "/en/sectors/:sectorSlug",
    meta: indexZDfgCTkpQxMeta || {},
    alias: indexZDfgCTkpQxMeta?.alias || [],
    redirect: indexZDfgCTkpQxMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/sectors/[sectorSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZDfgCTkpQxMeta?.name ?? "sectors-sectorSlug___it",
    path: indexZDfgCTkpQxMeta?.path ?? "/it/settori/:sectorSlug",
    meta: indexZDfgCTkpQxMeta || {},
    alias: indexZDfgCTkpQxMeta?.alias || [],
    redirect: indexZDfgCTkpQxMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/sectors/[sectorSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZDfgCTkpQxMeta?.name ?? "sectors-sectorSlug___zh",
    path: indexZDfgCTkpQxMeta?.path ?? "/zh/bumen/:sectorSlug",
    meta: indexZDfgCTkpQxMeta || {},
    alias: indexZDfgCTkpQxMeta?.alias || [],
    redirect: indexZDfgCTkpQxMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/sectors/[sectorSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: portfoliouMhfYOAQS3Meta?.name ?? "sectors-sectorSlug-portfolio___de",
    path: portfoliouMhfYOAQS3Meta?.path ?? "/de/sektoren/:sectorSlug/portfolio",
    meta: portfoliouMhfYOAQS3Meta || {},
    alias: portfoliouMhfYOAQS3Meta?.alias || [],
    redirect: portfoliouMhfYOAQS3Meta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/sectors/[sectorSlug]/portfolio.vue").then(m => m.default || m)
  },
  {
    name: portfoliouMhfYOAQS3Meta?.name ?? "sectors-sectorSlug-portfolio___en___default",
    path: portfoliouMhfYOAQS3Meta?.path ?? "/sectors/:sectorSlug/portfolio",
    meta: portfoliouMhfYOAQS3Meta || {},
    alias: portfoliouMhfYOAQS3Meta?.alias || [],
    redirect: portfoliouMhfYOAQS3Meta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/sectors/[sectorSlug]/portfolio.vue").then(m => m.default || m)
  },
  {
    name: portfoliouMhfYOAQS3Meta?.name ?? "sectors-sectorSlug-portfolio___en",
    path: portfoliouMhfYOAQS3Meta?.path ?? "/en/sectors/:sectorSlug/portfolio",
    meta: portfoliouMhfYOAQS3Meta || {},
    alias: portfoliouMhfYOAQS3Meta?.alias || [],
    redirect: portfoliouMhfYOAQS3Meta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/sectors/[sectorSlug]/portfolio.vue").then(m => m.default || m)
  },
  {
    name: portfoliouMhfYOAQS3Meta?.name ?? "sectors-sectorSlug-portfolio___it",
    path: portfoliouMhfYOAQS3Meta?.path ?? "/it/settori/:sectorSlug/portfolio",
    meta: portfoliouMhfYOAQS3Meta || {},
    alias: portfoliouMhfYOAQS3Meta?.alias || [],
    redirect: portfoliouMhfYOAQS3Meta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/sectors/[sectorSlug]/portfolio.vue").then(m => m.default || m)
  },
  {
    name: portfoliouMhfYOAQS3Meta?.name ?? "sectors-sectorSlug-portfolio___zh",
    path: portfoliouMhfYOAQS3Meta?.path ?? "/zh/bumen/:sectorSlug/wenjian-jia",
    meta: portfoliouMhfYOAQS3Meta || {},
    alias: portfoliouMhfYOAQS3Meta?.alias || [],
    redirect: portfoliouMhfYOAQS3Meta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/sectors/[sectorSlug]/portfolio.vue").then(m => m.default || m)
  },
  {
    name: _91serviceSlug_930vahbAPz86Meta?.name ?? "services-serviceSlug___de",
    path: _91serviceSlug_930vahbAPz86Meta?.path ?? "/de/dienstleistungen/:serviceSlug",
    meta: _91serviceSlug_930vahbAPz86Meta || {},
    alias: _91serviceSlug_930vahbAPz86Meta?.alias || [],
    redirect: _91serviceSlug_930vahbAPz86Meta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/services/[serviceSlug].vue").then(m => m.default || m)
  },
  {
    name: _91serviceSlug_930vahbAPz86Meta?.name ?? "services-serviceSlug___en___default",
    path: _91serviceSlug_930vahbAPz86Meta?.path ?? "/services/:serviceSlug",
    meta: _91serviceSlug_930vahbAPz86Meta || {},
    alias: _91serviceSlug_930vahbAPz86Meta?.alias || [],
    redirect: _91serviceSlug_930vahbAPz86Meta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/services/[serviceSlug].vue").then(m => m.default || m)
  },
  {
    name: _91serviceSlug_930vahbAPz86Meta?.name ?? "services-serviceSlug___en",
    path: _91serviceSlug_930vahbAPz86Meta?.path ?? "/en/services/:serviceSlug",
    meta: _91serviceSlug_930vahbAPz86Meta || {},
    alias: _91serviceSlug_930vahbAPz86Meta?.alias || [],
    redirect: _91serviceSlug_930vahbAPz86Meta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/services/[serviceSlug].vue").then(m => m.default || m)
  },
  {
    name: _91serviceSlug_930vahbAPz86Meta?.name ?? "services-serviceSlug___it",
    path: _91serviceSlug_930vahbAPz86Meta?.path ?? "/it/servizi/:serviceSlug",
    meta: _91serviceSlug_930vahbAPz86Meta || {},
    alias: _91serviceSlug_930vahbAPz86Meta?.alias || [],
    redirect: _91serviceSlug_930vahbAPz86Meta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/services/[serviceSlug].vue").then(m => m.default || m)
  },
  {
    name: _91serviceSlug_930vahbAPz86Meta?.name ?? "services-serviceSlug___zh",
    path: _91serviceSlug_930vahbAPz86Meta?.path ?? "/zh/fuwu/:serviceSlug",
    meta: _91serviceSlug_930vahbAPz86Meta || {},
    alias: _91serviceSlug_930vahbAPz86Meta?.alias || [],
    redirect: _91serviceSlug_930vahbAPz86Meta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/services/[serviceSlug].vue").then(m => m.default || m)
  },
  {
    name: index79ETnC9GNAMeta?.name ?? "whistleblowing___de",
    path: index79ETnC9GNAMeta?.path ?? "/de/whistleblowing",
    meta: index79ETnC9GNAMeta || {},
    alias: index79ETnC9GNAMeta?.alias || [],
    redirect: index79ETnC9GNAMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/whistleblowing/index.vue").then(m => m.default || m)
  },
  {
    name: index79ETnC9GNAMeta?.name ?? "whistleblowing___en___default",
    path: index79ETnC9GNAMeta?.path ?? "/whistleblowing/",
    meta: index79ETnC9GNAMeta || {},
    alias: index79ETnC9GNAMeta?.alias || [],
    redirect: index79ETnC9GNAMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/whistleblowing/index.vue").then(m => m.default || m)
  },
  {
    name: index79ETnC9GNAMeta?.name ?? "whistleblowing___en",
    path: index79ETnC9GNAMeta?.path ?? "/en/whistleblowing",
    meta: index79ETnC9GNAMeta || {},
    alias: index79ETnC9GNAMeta?.alias || [],
    redirect: index79ETnC9GNAMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/whistleblowing/index.vue").then(m => m.default || m)
  },
  {
    name: index79ETnC9GNAMeta?.name ?? "whistleblowing___it",
    path: index79ETnC9GNAMeta?.path ?? "/it/whistleblowing",
    meta: index79ETnC9GNAMeta || {},
    alias: index79ETnC9GNAMeta?.alias || [],
    redirect: index79ETnC9GNAMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/whistleblowing/index.vue").then(m => m.default || m)
  },
  {
    name: index79ETnC9GNAMeta?.name ?? "whistleblowing___zh",
    path: index79ETnC9GNAMeta?.path ?? "/zh/whistleblowing",
    meta: index79ETnC9GNAMeta || {},
    alias: index79ETnC9GNAMeta?.alias || [],
    redirect: index79ETnC9GNAMeta?.redirect,
    component: () => import("/home/forge/pininfarina.it/pages/whistleblowing/index.vue").then(m => m.default || m)
  },
  {
    name: pininfarinaOzhUtZevD9Meta?.name ?? undefined,
    path: pininfarinaOzhUtZevD9Meta?.path ?? "/sitemap.xml",
    meta: pininfarinaOzhUtZevD9Meta || {},
    alias: pininfarinaOzhUtZevD9Meta?.alias || [],
    redirect: pininfarinaOzhUtZevD9Meta?.redirect,
    component: () => import("/home/forge/pininfarina.it/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  }
]